import { defineStore } from 'pinia';
import { SocketModel } from '@/models/SocketModel';
import router from '@/router';
import { io } from 'socket.io-client';

export const useSocketStore = defineStore('socket', {
  state: () => ({
    main: null as any,
    isNotificationGerenciar: false as any,
    session: sessionStorage.getItem('notification'),
    notifications: sessionStorage.getItem('notification') ? JSON.parse(sessionStorage.getItem('notification')!) : [] as any[],
  }),
  getters: {
    mainSocket: (state) => state.main,
    isNotificationGerenciarSocket: (state) => state.isNotificationGerenciar,
    notificationsSession: (state) => state.notifications,
  },
  actions: {
    startSocket() {
      if (!this.main) {
        console.log('iniciou');
        this.main = io('https://node-indicar.azurewebsites.net');
      }
    },
    newListen(options: { name: string, callbackFunction: (data: any) => void }) {
      console.log('chamou action', options);
      if (this.main) {
        this.main.on(options.name, (data: any) => options.callbackFunction(data));
      }
    },
    notificationInGerenciar() {
      this.isNotificationGerenciar = !this.isNotificationGerenciar;
      console.log('action gerenciar notificacao', this.isNotificationGerenciar);
    },
    saveNewNotification(notification: any) {
      this.notifications.push(notification);
      console.log('mutations', this.notifications);
    },
    removeListen(options: { name: string }) {
      console.log('removeu action', options);
      if (this.main) {
        this.main.removeListener(options.name);
      }
    },
    disconnectSocket() {
      if (this.main) {
        this.main.disconnect();
      }
    },
  },
});
